$(function(){
  //open modal content
  $('.modal-open').on('click',function() {
    var targetModalItem = $(this).attr('href');
    var target = $(targetModalItem == "#" || targetModalItem == "" ? 'html' : targetModalItem);
    $('#modalBox').addClass('active');
    target.addClass('active');
    return false;
  });
  //close modal
  $('.close-modal').on('click',function(){
    $('#modalBox').removeClass('active');
    $('.modal-inner-content *').removeClass('active');
    return false;
  });
});
