$(function(){
  $(window).on('load resize',function(){
    var windowWidth = $(window).width();
    if(windowWidth > 960){
      $('.map-link li a').hover(function() {
        var targetid = $(this).attr('href');
        var targetid_hush = targetid.replace('#','.');
        var targetclass = targetid_hush.replace('List','');
        var maptarget = '.map-image svg ' + targetclass;
        $(maptarget).addClass('active');
        //console.log(targetclass);
      },function() {
        $('.map-image svg path').removeClass('active');
      });
    }
  });
});
