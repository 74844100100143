$(function () {
  /**
   * @param args.resourceURL String 選択肢の元になるモデルを取得するURL
   * @param args.params Object resourceURLに送信するパラメータ
   * @param args.$targetSelect jQueryObj 選択肢を変更される$(select)
   */
  function updateSelectOptions(args) {
    $.get(args.resourceURL, args.params)
      .done(function (data) {
        var newOptions = '<option value="">指定なし</option>';
        for (var i = 0, l = data.length; i < l; i++) {
          if (data[i]) {
            newOptions += '<option value="' + data[i].id + '">' +
              data[i].name + '</option>';
          }
        }
        args.$targetSelect.empty().append(newOptions);
      });
  }

  var $compensationTypeSelect = $('#compensation_type_id');
  var $searchCompensationSelect = $('#search_compensation_id');
  $compensationTypeSelect.on('change', function () {
    updateSelectOptions({
      resourceURL: window.SEARCH_COMPENSATIONS_URL,
      params: {compensation_type_id: $compensationTypeSelect.val()},
      $targetSelect: $searchCompensationSelect
    });
  });

  //// 市区町村検索で都道府県のチェックボックスを入れたら、配下の市区町村がチェックされる
  var $cityCheckbox = $('.js-city-checkbox');
  $('.js-check-all').on('change', function() {
    $cityCheckbox.prop('checked', this.checked);
  });

  //// 共通メソッド
  function updatePrefectureTitle() {
    var prefectureName = $('select#prefecture_id option:selected').text()
    if (prefectureName) {
      $('h2.pref-title').text(prefectureName);
    }
  }

  function updateCityInputType() {
    var inputValueArray = [];
    $('input:checkbox[name="city_ids[]"]:checked').each(function () {
      var id = $(this).val();
      inputValueArray.push($('label[for="city_ids_' + id + '"]').text());
    });
    $('#selected-prefecture').val(inputValueArray.join('、'));
  }

  function updateJobTypeInputValue() {
    var inputValueArray = [];
    $('input:checkbox[name="job_type_ids[]"]:checked').each(function () {
      var id = $(this).val();
      inputValueArray.push($('label[for="job_type_id_' + id + '"]').text());
    });
    $('#selected-job-types').val(inputValueArray.join('、'));
  }

  function updateEmploymentTypeInputValue() {
    var inputValueArray = [];
    $('input:checkbox[name="employment_type_ids[]"]:checked').each(function () {
      var id = $(this).val();
      inputValueArray.push($('label[for="employment_type_id_' + id + '"]').text());
    });
    $('#selected-employment-types').val(inputValueArray.join('、'));
  }

  //// 求人数処理
  function getJobCounter() {
    var prm = $('form#search_jobs_form').serialize();
    console.log("URL: " + window.JOB_COUNTER_URL + '?' + prm);

    var requestURL = window.JOB_COUNTER_URL + '?' + prm;
    $.get(requestURL)
      .done(function (job_counter) {
        var counter = job_counter.job_counter;
        $('button.decide-terms-btn em').text(counter);
      });
  }

  //// ロード時動作
  updateCityInputType();
  updateJobTypeInputValue();
  updateEmploymentTypeInputValue();

  //// 給与選択の結果をhidden_fieldに反映
  var $searchCompensationSelect = $('#search_compensation_id');
  $searchCompensationSelect.on('change', function () {
    $('#search_compensation_id').val($searchCompensationSelect.val());
    getJobCounter();
  });

  //// 雇用形態をinputに反映
  $('#employment-select .close-modal').on('click', function () {
    updateEmploymentTypeInputValue();
    getJobCounter();
  });

  //// 職種をinputに反映
  $('#jobtype-select .close-modal').on('click', function () {
    updateJobTypeInputValue();
    getJobCounter();
  });

  //// 都道府県をhidden fieldに反映
  $('#city-select .close-modal').on('click', function () {
    updateCityInputType();
    getJobCounter();
    updatePrefectureTitle();
  });

  //// 仕事の特徴を変えたら、求人数も変える
  $('form#search_jobs_form input:checkbox[name="job_category_ids[]"]').on('change', function () {
    getJobCounter();
  });
});
