var postcodeList;
var initialLoading = 0;

$(function() {

  // 同意チェックボックス
  $('button#chk_act').css({'display': 'none'});
  $('.check-box input#agree[type=checkbox]').click(function () {
    if ($(this).is(':checked') === true) {
      $('#chk_dis').css({'display': 'none'});
      $('#chk_act').css({'display': 'block'});
      console.log('Case for Click: checked');
    } else {
      $('#chk_dis').css({'display': 'flex'});
      $('#chk_act').css({'display': 'none'});
      console.log('Case for Click: NOT checked');
    }
  });

  // 郵便番号検索関連
  var API_ID =
    'dj0zaiZpPU5WakVjS29tSDdqZCZzPWNvbnN1bWVyc2VjcmV0Jng9NmU-'; // production
  // 'dj0zaiZpPU01YW1PcEZBeENtVyZzPWNvbnN1bWVyc2VjcmV0Jng9MjU-'; // development
  $.postcode = {
    create: function(code){
      /*json　読み込み*/
      $.ajax({
        type: 'GET',
        url: '/httpapirelay/relayto?query='+code+'&appid='+ API_ID +'&output=json',
        dataType: 'jsonp',
        success: function(json){
          postcodeList.init(json)
        },
        error: function(XMLHttpRequest, textStatus, errorThrown){
        }
      });

      postcodeList = {
        ready: false,
        json : {},
        jsonNum : 0,
        init: function(json){
          this.json = json;
          this.jsonCount = json.ResultInfo.Count;
          if (this.jsonCount!==0) {
            this.jsonNum = json.Feature.length;
          }
          this.parseJson();
          this.ready=true;
        },
        parseJson: function(){
          /*表示用html生成*/
          $('#address1').val('');
          if (this.jsonCount == 0) {
            $('#zip_tip').fadeIn().children('.tipInner').html('無効な郵便番号です');
          } else if (this.jsonCount > 1) {
            for(var i =0;i<this.jsonNum;i++){
              var item = this.json.Feature[i];
              var term = item.Property.Address.split( '（' );

              $('<option>'+term[0]+'</option>').appendTo($select_html);
              $('#address1').val($("#select_double option:eq(0)").val());
              $("#select_double").change(function(){
                $('#address1').val($(this).val()).removeClass('is_error').focus().keyup();
              });
            }
          } else {
            for(var i =0;i<this.jsonNum;i++){
              var item = this.json.Feature[i];
              var term = item.Property.Address.split( '（' );
              $('#address1').val(term[0]).removeClass('is_error').focus().keyup();
            }
          }
        }
      }

    }
  };

  // バリデーション関連
  $.validate = {

    // classに条件を割り当てる
    chk: {
      required: function(txt,t){
        var placeholderValue = $(t).attr('placeholder');
        if($(t).hasClass("group")){
          var flag = 0;
          $("input,select",t).each(function(){
            if($(this).val().length>0) flag++;
          });
          if (typeof placeholderValue !== 'undefined') {
            return txt && txt !== placeholderValue && flag==$("input,select",t).length;
          } else {
            return txt && flag==$("input,select",t).length;
          }
        }else{
          if (typeof placeholderValue !== 'undefined') {
            return txt && txt !== placeholderValue && txt.length > 0;
          } else {
            return txt && txt.length > 0;
          }
        }
      },
      namaekanji: /^[一-龥ぁ-んァ-ンＡ-Ｚａ-ｚa-zA-Z0-9\s\u3000\u30FC\u3005\u3007\u303B\u3400-\u9FFF\uF900-\uFAFF\uD840-\uD87F\uDC00-\uDFFF]+$/,
      zenka: /^[ァ-ンＡ-Ｚａ-ｚ\s\u3000\u30FC]+$/,
      kana: /^[ァ-ン\u30FC]+$/,
      hankaku: /^[a-zA-Z0-9@\;\:\[\]\^\+^\=\/\!\*\"\#\$\%\&\'\(\)\,\.\-\_\?\\\s]*$/, //"
      email: /^[A-Za-z0-9-_.+]+@[\w\.-]+\.\w{2,}$/,
      tel: /^[0-9０-９]{10}$|^[0-9０-９]{11}$|^[0-9０-９-－−]{12}$|^[0-9０-９-－−]{13}$/,
      numonly: /^\d*$/,
      zip: /^[0-9-]+$/,
      space:/([一-龥ぁ-んァ-ンＡ-Ｚａ-ｚa-zA-Z0-9\u30FC\u3005\u3007\u303B\u3400-\u9FFF\uF900-\uFAFF\uD840-\uD87F\uDC00-\uDFFF]+)[\s\u3000]+([一-龥ぁ-んァ-ンＡ-Ｚａ-ｚa-zA-Z0-9\u30FC\u3005\u3007\u303B\u3400-\u9FFF\uF900-\uFAFF\uD840-\uD87F\uDC00-\uDFFF]+)+$/,
      check: function(txt,t){
        return $("input:checked",t).length>0;
      },
      radio: function(txt,t){
        return $("input:checked",t).length>0;
      },
      retype: function(txt,t){
        var elm = $("#"+$(t).attr("class").split("retype\-")[1].split(/\b/)[0]);
        if(elm.hasClass("group")){
          var chktxt = $("input",elm), txt = $("input",t);
          for(var i=0,flag=false;i<chktxt.length;i++){
            if(chktxt[i].value==txt[i].value) flag = true;
            else flag = false;
          }
          if(flag) return true;
        }else{
          return elm.val() == txt;
        }
      },
      min: function(txt,t){
        var length = $(t).attr("class").match(/min(\d+)/) ? RegExp.$1 : null;
        if(txt.length>0) return txt.length >= length;
      },
      max: function(txt,t){
        var length = $(t).attr("class").match(/max(\d+)/) ? RegExp.$1 : null;
        if(txt.length>0) return txt.length <= length;
      }
    },
    fn: function(t,options){
      msgs = $.extend({
        namaekanji: "全角文字で入力して下さい。",
        zenka: "全角カナで入力して下さい。",
        retype: "直前の項目と同じ内容を入力してください。",
        email: "正しいEメールアドレスを入力してください。",
        hankaku: "全角文字は使えません。",
        tel: "正しい電話番号を入力してください。",
        numonly: "数字のみで入力してください。",
        zip: "正しい郵便番号を入力してください。",
        min: "文字以上で入力してください。",
        max: "文字以内で入力してください。",
        check: "ひとつ以上を選択してください。",
        radio: "いずれかを選択してください。",
        required: "入力が必要です。",
        submit: "必須項目を正しく入力してください。",
        space: "姓（セイ）と名（メイ）の間はスペースをあけてください。"
      },options);
      var txt = $(t).val();
      var CL = $(t).attr("class");

      // 分割項目は中のvalueを足したものをtxtにする
      if(CL.match(/group/)){
        txt = "";
        var inputs = $("input,select",t);
        inputs.each(function(i){
          txt += $(this).val();

          // .email には@を追加
          if(CL.match(/email/) && i==0 && $(this).val().length>0)
            txt += "@";
        });
      }

      // エラー時の動作
      var check = {
        isError: false,
        failed: function(t,c){
          var msg = msgs[c];
          var selectorName = '.notice[data-validation="' + t.id + '"]';
          if(c.match(/min/) && CL.match(/min(\d+)/)) msg = RegExp.$1+msgs[c];
          else if(c.match(/max/) && CL.match(/max(\d+)/)) msg = RegExp.$1+msgs[c];

          // 初期ロードではエラーメッセージが出ないようにする
          console.log('initialLoading=' + initialLoading);
          if (initialLoading > 0) {
            $(selectorName).addClass('error');
          }
          $(selectorName).text(msg);
          $('#'+t.id+'').addClass('is_error');
          $('input#'+t.id+',textarea#'+t.id+'').css('background-color','#ffafff');
          this.isError = true;
        }
      };

      var chk = $.validate.chk;
      for(c in chk){
        if(CL.match(c)){
          if(typeof(chk[c]) != "function"){
            if(txt && !txt.match(chk[c])){
              check.failed(t,c);
              break;
            }
          }else{
            if(!chk[c](txt,t)){
              check.failed(t,c);
              break;
            }
          }
        }
      }

      // エラーがなければチップを非表示にする
      if(!check.isError){
        $('#'+t.id+'').removeClass('is_error');
        $('.notice[data-validation="' + t.id + '"]').removeClass('error');
        $('input[type="text"]#'+t.id+', input[type="email"]#'+t.id+', input[type="tel"]#'+t.id+', textarea#'+t.id+'').css('background-color','#fffed5');
      }
    }
  };

  /* 初期化と適用 */
  $.fn.validation = function(options){
    if($(this).length<1) return false;

    var form = this, tipDiv = 500;
    if(!options) var options = {};
    var setting = $.extend({
      submit: ".btn_next",
      position: "left",
      top: 0,
      left: 15,
      zip: null,
      zip2: null,
      pref: null,
      addr1: null,
      addr2: null,
      addr3: null
    },options.extension);

    // チェックするclassを含んでいるinput要素
    var inputs = $("input[type='text'],input[type='password'],input[type='tel'],input[type='number'],input[type='email'],textarea,select",form)
      .filter(function(){
        return this.className.match(/required|retype|email|namaekanji|zenka|kana|hankaku|numonly|tel|zip|space/);
      });
    var validate = function(target){
      $.validate.fn(target,options.msgs);

      if($("div.requiredTip:visible").length>0 || $(".is_error").length>0){
        $(setting.submit).attr('disabled', true).html('未入力の項目があります').css({'color':'#A7A8A7', 'text-decoration':'none'});
      }else{
        $(setting.submit).attr('disabled', false).html('確認する').css({color:'#fff'}).addClass('uni-btn-02').removeClass('btn-03');
        $('.uni-btn-02').click(function () {
          //電話番号変換
          var $tel = $("input#tel");
          var txt = $tel.val();
          var han = txt.replace(/[Ａ-Ｚａ-ｚ０-９－−]/g,function(s){return String.fromCharCode(s.charCodeAt(0)-0xFEE0)});
          var ptel = han.replace(/-/g,"");
          $tel.val(ptel);
        });
      }
    };

    // 上記のinput,select,textareaをチェックする
    inputs.each(function(){
      validate(this);
      $(this).css('background-color','#fffed5');
      $(this).bind("keyup blur",function(){validate(this)})
        .filter("select").change(function(){validate(this)});
    });

    // 上記以外の分割、チェックボックス、ラジオボタンをチェックする
    $(".group,.check,.radio").each(function(){
      var group = this;
      validate(this);
      $('input[type="text"], input[type="email"], input[type="tel"], textarea').css('background-color','#fffed5');
      if($(this).hasClass("group")){
        $("input",this).keyup(function(){validate(group)})
          .blur(function(){validate(group)});
        $("select",this).change(function(){validate(group)});
      }else{
        $("input",this).click(function(){validate(group)});
      }
    });

    $("#postcode").click(function(){
      var code = $(this).prev().val();
      if (code=='') {
        return false;
      } else {
        $.postcode.create(code);
        return false;
      }
    });

    //離脱チェック
    var isChanged = false;
    $(window).bind("beforeunload", function() {
      // console.log(isChanged);
      if (isChanged) {
        return "入力が途中ですが";
      }
    });
    $("form input, form select, form textarea").change(function() {
      isChanged = true;
    });
    $("button[type=submit], #agree").click(function() {
      isChanged = false;
    });

    initialLoading += 1;
  }
});
